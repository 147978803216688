<template>
  <div class="payCharge">
    <div class="cookPart">
      <el-form
        ref="dataForm"
        class="centerForm"
        :model="dataForm"
        label-width="220px"
      >
        <h5>用户资料</h5>
        <div class="userInfo">
          <div class="listTri">
            昵称：<b>{{ user.nickname }}</b>
          </div>
          <div class="listTri">
            手机号码：<b>{{ user.mobile }}</b>
          </div>
          <div class="listTri">
            用户id：<b>{{ user.uuid }}</b>
          </div>
          <div class="listTri">
            余额：<b>{{ user.balance }}</b>
          </div>
        </div>
        <h5>充值活动</h5>
        <div class="listBtn">
          活动最高赠送金额<b>{{ limitMoney }}</b> ，已赠送<b>{{
            giftAmount
          }}</b>
        </div>

        <el-form-item label="用户id：" prop="uuid" class="inlineForm">
          <el-input v-model.trim.number="dataForm.uuid" clearable />
          <el-button @click="getRechargeByUuid" type="info">搜索用户</el-button>
        </el-form-item>
        <el-form-item label="充值金额：" prop="balance">
          <el-radio-group v-model="dataForm.id">
            <el-radio-button
              v-for="item in rechargeList"
              :key="`change` + item.id"
              :label="item.id"
              :value="item.rechargeAmount"
            >
              充值{{ item.rechargeAmount }}元送{{
                item.giftAmount
              }}元</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <div class="rightConsole">
          <el-button @click="open">充值</el-button>
        </div>
      </el-form>
    </div>
    <!--    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" />-->
  </div>
</template>

<script>
import { keys, pick } from 'lodash';
// import AddOrUpdate from './cardRecharge-add-or-update';

export default {
  components: {
    // AddOrUpdate,
  },
  data() {
    return {
      timer: null,
      rechargeList: [],
      giftAmount: 0,
      limitMoney: 0,
      dataForm: {
        id: null,
        uuid: null,
        balance: null,
      },
      user: {
        nickname: '',
      },
      addOrUpdateVisible: false,
    };
  },
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
    },
    open() {
      if (!this.dataForm.uuid) {
        this.$message.error('请选择充值的用户');
        return false;
      }
      if (!this.dataForm.id) {
        this.$message.error('请选择充值金额参数');
        return false;
      }
      let recharge = this.rechargeList.find(
        (item) => item.id === this.dataForm.id,
      );
      if (recharge && recharge.rechargeAmount) {
        let message =
          '是否对：' +
          this.user.nickname +
          '进行充值,充值金额：' +
          recharge.rechargeAmount;
        this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.dataFormSubmit();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消充值',
            });
          });
      }
    },
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.id) {
        let recharge = this.rechargeList.filter(
          (item) => item.id === this.dataForm.id,
        )[0];
        this.$http({
          url: `/mall/user/rechargeByUuid`,
          method: 'post',
          data: {
            uuid: this.dataForm.uuid,
            rechargeMoney: recharge.rechargeAmount,
            giftAmount: recharge.giftAmount,
            needPay: recharge.needPay,
            fromType: 7,
            rechargeActivityId: recharge.rechargeActivityId,
            rechargeActivityRuleId: recharge.rechargeActivityRuleId,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getRechargeByUuid();
            this.$message({
              message: data.msg,
              type: 'success',
              duration: 1500,
            });
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
            });
          }
        });
      }
    },
    // 查询
    getRechargeByUuid() {
      if (this.dataForm.uuid) {
        this.$http({
          url: `/tc/userrecharge/rechargeGroup`,
          method: 'post',
          data: {
            uuid: this.dataForm.uuid,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.rechargeList = data.data.list;
            this.user = data.data.user;
            this.limitMoney = data.data.limitMoney;
            this.giftAmount = data.data.giftAmount;
          } else {
            this.rechargeList = [];
            this.user = {};
            this.limitMoney = 0;
            this.giftAmount = 0;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.payCharge {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  .cookPart {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    .centerForm {
      position: relative;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      h5 {
        font-size: 24px;
        line-height: 1.8;
        font-weight: 600;
        margin: 0;
      }
      .userInfo {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .listTri {
          flex: 0 0 25%;
          font-size: 20px;
          color: #000;
        }
      }
      .el-form-item {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        .el-form-item__label {
          line-height: 60px;
          font-size: 40px;
          text-align: center;
          float: none;
        }
        .el-form-item__content {
          line-height: 60px;
          margin-left: 0 !important;
          flex: 1;
          .el-input {
            width: 100%;
            .el-input__inner {
              height: 60px;
              font-size: 28px;
            }
          }
        }
      }
      .inlineForm {
        .el-form-item__content {
          display: flex;
          flex-wrap: wrap;
          .el-input {
            flex: 1;
            width: auto;
          }
        }
      }
      .rightConsole {
        position: relative;
        .el-button {
          padding: 0;
          width: 100%;
          border-radius: 0;
          background-color: #409eff;
          color: #fff;
          line-height: 60px;
          border: none;
          font-size: 28px;
        }
      }
    }
  }
}
.listBtn {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  b {
    color: #f00;
    margin: 0 0 0 5px;
    font-size: 24px;
  }
}
</style>
