<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="公司" class="typeInput">
          <el-select
            v-model="searchForm.companyIds"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="日期" class="typeInput">
          <el-date-picker
            v-model="date"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="openReport()"
            v-if="isAuth('tc:companyMoneyBill:openReport')"
            >导出报表</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <h4>费用总览</h4>
    <div class="listBtn">
      <span
        >入账金额总计<b>{{ this.totalList.totalInMoney }}</b></span
      >
      <span
        >支出金额总计<b>{{ this.totalList.totalOutMoney }}</b></span
      >
      <span
        >收支净额总计<b>{{ this.totalList.totalMoney }}</b></span
      >
    </div>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="客户公司名称"
      >
      </el-table-column>
      <el-table-column
        prop="date"
        header-align="center"
        align="center"
        label="日期"
      >
      </el-table-column>
      <el-table-column
        prop="openingBalance"
        header-align="center"
        align="center"
        label="期初余额"
      >
      </el-table-column>
      <el-table-column
        prop="inMoney"
        header-align="center"
        align="center"
        label="入账金额"
      >
      </el-table-column>
      <el-table-column
        prop="inCount"
        header-align="center"
        align="center"
        label="入账笔数"
      >
      </el-table-column>
      <el-table-column
        prop="outMoney"
        header-align="center"
        align="center"
        label="支出金额"
      >
      </el-table-column>
      <el-table-column
        prop="outCount"
        header-align="center"
        align="center"
        label="支出笔数"
      >
      </el-table-column>
      <el-table-column
        prop="closingBalance"
        header-align="center"
        align="center"
        label="日终金额"
      >
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import { getCompanyList } from '@/utils/options.js';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      totalPage: 0,
      searchForm: {
        companyIds: [],
        startDate: '',
        endDate: '',
      },
      date: [],
      totalList: {
        totalInMoney: '',
        totalMoney: '',
        totalOutMoney: '',
      },
      companyList: [],
      addOrUpdateVisible: false,
    };
  },
  components: {},
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getCompanyList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.date?.length) {
        this.searchForm.startDate = this.date[0];
        this.searchForm.endDate = this.date[1];
      } else {
        this.searchForm.startDate = '';
        this.searchForm.endDate = '';
      }
      this.$getDataList({
        url: '/report/company/companyMoneyBill',
        params: {
          ...this.searchForm,
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          companyIds: this.searchForm.companyIds.join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list;
            this.totalList = data.total;
            this.totalPage = data.page.totalCount;
          } else {
            this.dataList = [];
          }
        },
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },

    openReport() {
      let companyIds = this.searchForm.companyIds.join(',');
      window.open(`${process.env.VUE_APP_TABLE_BASE_URL}
      company_money_bill.rptdesign&__showtitle=false&pageOverflow=2&__locale=zh_CN
      &company_ids=${companyIds}&start_date=${this.searchForm.startDate}&end_date=${this.searchForm.endDate}`);
    },
  },
};
</script>
