<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput" label="合同名字">
          <el-input
            v-model="searchForm.contractName"
            placeholder="合同名字"
            clearable
          />
        </el-form-item>

        <el-form-item class="typeInput" label="合同编号">
          <el-input
            v-model="searchForm.contractSn"
            placeholder="合同编号"
            clearable
          />
        </el-form-item>

        <el-form-item label="供应商" prop="orgId">
          <el-select
            v-model="searchForm.orgId"
            placeholder="配餐公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="日期" class="typeInput">
          <el-date-picker
            v-model="time"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="states" label="状态">
          <el-select
            v-model="searchForm.states"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button
            v-if="isAuth('tc:contract:save')"
            type="primary"
            @click="addOrUpdateHandle()"
            >新增</el-button
          >
          <el-button
            type="danger"
            size="mini"
            v-if="isAuth('tc:contract:changeState')"
            :disabled="dataListSelections.length <= 0"
            @click="changeState()"
            >终止合同</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="contractName"
        header-align="center"
        align="center"
        width="120"
        label="合同名字"
      ></el-table-column>

      <el-table-column
        prop="contractSn"
        header-align="center"
        align="center"
        width="120"
        label="合同编号"
      >
      </el-table-column>
      <el-table-column
        prop="contractMoney"
        header-align="center"
        align="center"
        width="150"
        label="合同金额"
      ></el-table-column>
      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        width="120"
        label="配餐公司名称"
      ></el-table-column>
      <el-table-column
        prop="contractDate"
        header-align="center"
        align="center"
        width="120"
        label="合同有效期"
      ></el-table-column>
      <el-table-column
        prop="contact"
        header-align="center"
        align="center"
        width="120"
        label="联系人"
      ></el-table-column>
      <el-table-column
        prop="contactMobile"
        header-align="center"
        align="center"
        width="120"
        label="联系人电话"
      ></el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        width="120"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state === 0" size="small">未生效</el-tag>
          <el-tag v-else-if="scope.row.state === 1" size="small">生效中</el-tag>
          <el-tag v-else-if="scope.row.state === 2" size="small">已失效</el-tag>
          <el-tag v-else-if="scope.row.state === 3" size="small">已终止</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        header-align="center"
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:contract:update') && scope.row.state === 0"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, false)"
            >编辑</el-button
          >
          <el-button
            v-if="isAuth('tc:contract:info')"
            type="text"
            size="small"
            @click="showDetails(scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:contract:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './contract-add-or-update';
import { threeListMixin, listMixin, normal } from '@/mixins';
import { getOrgList } from '@/utils/options';

export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      time: [],
      searchForm: {
        contractName: '',
        states: [0, 1],
        contractSn: '',
        orgId: '',
        startDate: '',
        endDate: '',
      },
      isUseOptions: [
        {
          isUse: true,
          name: '是',
        },
        {
          isUse: false,
          name: '否',
        },
      ],
      orgList: [],
      stateList: [
        { id: 0, state: '未生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已失效' },
        { id: 3, state: '已终止' },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
    this.getOrgList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      if (this.time?.length) {
        this.dataForm.startDate = this.time[0];
        this.dataForm.endDate = this.time[1];
      } else {
        this.dataForm.startDate = '';
        this.dataForm.endDate = '';
      }
      this.$http({
        url: '/tc/contract/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          perPage: this.pageSize,
          contractName: this.searchForm.contractName,
          contractSn: this.searchForm.contractSn,
          orgId: this.searchForm.orgId,
          startDate: this.dataForm.startDate,
          endDate: this.dataForm.endDate,
          states: this.searchForm.states.join(','),
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 查看详情
    showDetails(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些合同进行删除操作?`,
        url: '/tc/contract/delete',
        data: ids,
        after: () => {
          this.getDataList();
        },
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    changeState(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否终止这些合同?`,
        url: '/tc/contract/changeState',
        data: ids,
      });
    },
  },
};
</script>
