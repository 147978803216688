<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="用户名" class="typeInput">
          <el-input
            v-model="searchForm.userNameSearch"
            placeholder="用户名"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="注册手机号" class="typeInput">
          <el-input
            v-model="searchForm.registerMobileSearch"
            placeholder="注册手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="是否团餐用户" class="typeInput">
          <el-select v-model="searchForm.isGroupSearch" filterable clearable>
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="团餐绑定手机号" class="typeInput">
          <el-input
            v-model="searchForm.mobileSearch"
            placeholder="团餐绑定手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="日期" class="typeInput">
          <el-date-picker
            v-model="date"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="openReport()"
            v-if="isAuth('tc:userBill:openReport')"
            >导出报表</el-button
          >
        </el-form-item>
      </div>
    </el-form>

    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="用户名"
      >
      </el-table-column>
      <el-table-column
        prop="uuid"
        header-align="center"
        align="center"
        label="用户id"
      >
      </el-table-column>
      <el-table-column
        prop="registerMobile"
        header-align="center"
        align="center"
        label="注册手机号"
      >
      </el-table-column>
      <el-table-column
        prop="isGroupText"
        header-align="center"
        align="center"
        label="是否团餐用户"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="团餐绑定手机号"
      >
      </el-table-column>
      <el-table-column
        prop="date"
        header-align="center"
        align="center"
        label="日期"
      >
      </el-table-column>
      <el-table-column
        prop="startBalance"
        header-align="center"
        align="center"
        label="日期初余额"
      >
      </el-table-column>
      <el-table-column
        prop="inBalance"
        header-align="center"
        align="center"
        label="收入"
      >
      </el-table-column>
      <el-table-column
        prop="balanceInCount"
        header-align="center"
        align="center"
        label="笔数"
      >
      </el-table-column>

      <el-table-column
        prop="outBalance"
        header-align="center"
        align="center"
        label="支出"
      >
      </el-table-column>

      <el-table-column
        prop="balanceOutCount"
        header-align="center"
        align="center"
        label="笔数"
      >
      </el-table-column>

      <el-table-column
        prop="endBalance"
        header-align="center"
        align="center"
        label="日终余额"
      >
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      totalPage: 0,
      searchForm: {
        userNameSearch: '',
        registerMobileSearch: '',
        isGroupSearch: '',
        mobileSearch: '',
        startDate: '',
        endDate: '',
      },
      totalBalance: '',
      date: [],

      groupList: [
        {
          id: 0,
          name: '否',
        },
        {
          id: 1,
          name: '是',
        },
      ],
    };
  },
  components: {},
  activated() {
    this.getDataList(1);
  },
  created() {},
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.date?.length) {
        this.searchForm.startDate = this.date[0];
        this.searchForm.endDate = this.date[1];
      } else {
        this.searchForm.startDate = '';
        this.searchForm.endDate = '';
      }
      this.$getDataList({
        url: '/report/mallUser/userBill',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          ...this.searchForm,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list;
            this.totalPage = data.page.totalCount;
          } else {
            this.dataList = [];
          }
        },
      });
    },

    openReport() {
      window.open(
        `${process.env.VUE_APP_TABLE_BASE_URL}
      user_balance_bill.rptdesign&__showtitle=false&pageOverflow=2&__locale=zh_CN
      &user_name_search=${this.searchForm.userNameSearch}&register_mobile_search=${this.searchForm.registerMobileSearch}
      &is_group_search=${this.searchForm.isGroupSearch}&mobile_search=${this.searchForm.mobileSearch}
      &start_date=${this.searchForm.startDate}&end_date=${this.searchForm.endDate}`.replaceAll(
          ' ',
          '',
        ),
      );
    },
    unique(arr) {
      // 根据唯一标识orderId来对数组进行过滤
      const res = new Map(); //定义常量 res,值为一个Map对象实例
      //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
    },
  },
};
</script>
