<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @submit.native.prevent.stop="handleSubmit"
      label-width="80px"
    >
      <el-form-item label="ID" prop="id" v-if="dataForm.id">
        <el-input
          v-model="dataForm.id"
          :disabled="true"
          placeholder="ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type" class="fullList">
        <el-radio-group v-model="type" :disabled="disabled">
          <el-radio
            v-for="(type, index) in typeList"
            :key="index"
            :label="index"
          >
            {{ type }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上级店铺" prop="parentId" v-if="type === 1">
        <el-select
          v-model="dataForm.parentId"
          :disabled="disabled"
          placeholder="上级店铺名称"
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="区域" prop="cityIds">
        <el-select
          v-model="cityIds[0]"
          :disabled="disabled"
          @change="changeCity(2, cityIds[0])"
          clearable
          filterable
          placeholder="区域"
        >
          <el-option
            v-for="item in cityList.province"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="cityIds[1]"
          v-if="cityIds[0]"
          @change="changeCity(3, cityIds[1])"
          :disabled="disabled"
          clearable
          filterable
          placeholder="区域"
        >
          <el-option
            v-for="item in cityList.city"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="cityIds[2]"
          v-if="cityIds[1]"
          @change="changeCity(4, cityIds[2])"
          :disabled="disabled"
          clearable
          filterable
          placeholder="区域"
        >
          <el-option
            v-for="item in cityList.zhen"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="cityIds[3]"
          v-if="cityIds[2]"
          @change="changeCity(5, cityIds[3])"
          :disabled="disabled"
          clearable
          filterable
          placeholder="区域"
        >
          <el-option
            v-for="item in cityList.street"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="cityIds[4]"
          v-if="cityIds[3]"
          @change="changeCity(6, cityIds[4])"
          :disabled="disabled"
          clearable
          filterable
          placeholder="区域"
        >
          <el-option
            v-for="item in cityList.house"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="店铺名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="店铺名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="creditCode">
        <el-input
          v-model="dataForm.creditCode"
          :disabled="disabled"
          placeholder="统一社会信用代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input
          v-model="dataForm.contact"
          :disabled="disabled"
          placeholder="联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactMobile">
        <el-input
          v-model="dataForm.contactMobile"
          :disabled="disabled"
          placeholder="联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否使用" prop="isUse">
        <el-checkbox v-model="dataForm.isUse">是否使用</el-checkbox>
      </el-form-item>
      <el-form-item label="供应商图片" prop="imageUrl">
        <el-upload
          class="avatar-uploader"
          :action="url"
          :show-file-list="false"
          :on-success="successHandle"
          :before-upload="beforeUploadHandle"
        >
          <img
            v-if="dataForm.imageUrl"
            :src="dataForm.imageUrl"
            style="max-width: 100%"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-button size="small" @click="$dialog('certificateAddOrUpdate')"
        >上传证件</el-button
      >
      <el-table :data="certificateList" border style="width: 100%">
        <el-table-column header-align="center" align="center" label="证件名称">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.name"
              placeholder="证件名称"
              :disabled="disabled"
            />
          </template>
        </el-table-column>

        <el-table-column header-align="center" align="center" label="预览图">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.url"
              fit="cover"
              @click="openImg(scope.row.url)"
            />
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="到期日">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.expiryDate"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="disabled"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="地址" prop="address" class="inlineBtn">
        <el-input
          v-model="dataForm.address"
          :disabled="disabled"
          placeholder="地址"
          @change="tranAddress"
        >
        </el-input>
        <div class="mapBlock">
          <el-button @click="openForm" type="info" v-if="!chooseVisible"
            >打开地图</el-button
          >
          <el-button @click="closeForm" type="warning" v-else
            >关闭地图</el-button
          >
          <map-choose
            ref="mapchoose"
            v-if="chooseVisible"
            @newAddress="getNewAddress"
            class="mapWay"
          />
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
    <certificate-add-or-update
      v-if="certificateAddOrUpdateVisible"
      ref="certificateAddOrUpdate"
      @success="getCertificate"
    ></certificate-add-or-update>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import CertificateAddOrUpdate from './certificate-add-or-update';
import mapChoose from '@/components/addressChoose';
import { getOrgList, getCityList } from '@/utils/options';
import { keys, pick } from 'lodash';
export default {
  mixins: [normal],
  components: {
    CertificateAddOrUpdate,
    mapChoose,
  },
  data() {
    return {
      disabled: false,
      certificateAddOrUpdateVisible: false,
      visible: false,
      chooseVisible: false,
      markersLayer: null,
      geometries: null,
      url: '',
      dataForm: {
        id: 0,
        parentId: '',
        name: '',
        isUse: true,
        creditCode: '',
        contact: '',
        contactMobile: '',
        address: '',
        longitude: null,
        latitude: null,
        imageUrl: '',
      },
      certificateList: [],
      cityIds: [],
      type: 0,
      orgList: [],
      cityList: {
        province: [],
        city: [],
        zhen: [],
        street: [],
        house: [],
      },
      typeList: ['总店铺', '分店铺'],
      dataRule: {
        name: [
          { required: true, message: '供应商名字不能为空', trigger: 'blur' },
        ],
        parentId: [
          { required: true, message: '上级店铺名称不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  methods: {
    handleSubmit() {
      return false;
    },
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.certificateList = [];
      this.cityIds = [];
      this.visible = true;
      this.dataForm.parentId = '';
      this.url =
        this.$http.BASE_URL +
        `/sys/oss/upload?token=${this.$cookie.get('token')}`;
      this.getOrgList();
      this.getCityList(1);
      this.openForm();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/cc/org/id/query/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = pick(data.data, keys(this.dataForm));
              this.certificateList = data.data.certificateList;
              if (
                this.dataForm.parentId !== 0 &&
                this.dataForm.parentId !== null
              ) {
                this.type = 1;
              }
              let city = data.data.cityId;
              let i;
              let cityId;
              let length = 100;
              if (city) {
                for (i = 1; i < 6; i++) {
                  let size = 1000000000000 / length;
                  if (city % size > 0) {
                    cityId = ((city / size).toFixed() % length) * size;
                  } else {
                    cityId = city;
                  }
                  this.cityIds.push(cityId);
                  this.getCityList(
                    i,
                    i === 1 ? '' : this.cityIds[i - 2],
                    this.cityIds.join(','),
                  );
                  length = length * 100;
                  if (i >= 3) {
                    length = length * 10;
                  }
                  if (city === cityId) {
                    return;
                  }
                }
              }
            }
          });
        }
      });
    },
    openForm() {
      this.chooseVisible = true;
      this.$nextTick(() => {
        if (this.dataForm.address) {
          this.$refs.mapchoose.getAddress(this.dataForm.address);
        } else {
          this.$refs.mapchoose.getAddress('佛山市');
        }
      });
    },
    beforeUploadHandle(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif'
      ) {
        this.$message.error('只支持jpg、png、gif格式的图片！');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过10MB哦!');
        return false;
      }
    },
    // 上传成功
    successHandle(response, file, fileList) {
      if (response && response.code === 0) {
        this.dataForm.imageUrl = response.title;
      } else {
        this.$message.error(response.msg);
      }
    },
    closeForm() {
      this.chooseVisible = false;
    },
    getNewAddress(address) {
      this.dataForm.address = address;
      this.tranAddress(address);
    },
    async tranAddress(data) {
      if (this.chooseVisible) {
        const address = await this.$refs.mapchoose.getAddress(data);
        if (address !== null) {
          this.dataForm.latitude = address.latitude;
          this.dataForm.longitude = address.longitude;
        }
      }
    },
    getCertificate(row) {
      this.certificateList.push(...row);
    },
    deleteHandle(id) {
      this.certificateList = this.certificateList.filter((i) => i.id !== id);
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    changeCity(level, parentId, value) {
      this.getCityList(level, parentId, value);
      this.cityIds = this.cityIds.splice(0, level - 1);
    },
    getCityList(level, parentId, value) {
      getCityList(level, parentId, value).then(({ data }) => {
        if (data && data.status === 0) {
          switch (level) {
            case 1:
              this.cityList.province = data.data.options;
              break;
            case 2:
              this.cityList.city = data.data.options;
              break;
            case 3:
              this.cityList.zhen = data.data.options;
              break;
            case 4:
              this.cityList.street = data.data.options;
              break;
            case 5:
              this.cityList.house = data.data.options;
              break;
            default:
              break;
          }
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.type === 0) {
        this.dataForm.parentId = null;
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/org/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
              certificateList: this.certificateList,
              cityId: this.cityIds[this.cityIds.length - 1],
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
