<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="配餐公司" prop="orgId">
        <el-select
          v-model="dataForm.orgId"
          placeholder="供应商"
          :disabled="disabled"
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="合同名称" prop="contractName">
        <el-input
          v-model="dataForm.contractName"
          :disabled="disabled"
          placeholder="合同名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="合同编号" prop="contractSn">
        <el-input
          v-model="dataForm.contractSn"
          :disabled="disabled"
          placeholder="合同编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="合同金额" prop="contractMoney">
        <el-input-number
          v-model="dataForm.contractMoney"
          :disabled="disabled"
          placeholder="合同金额"
        ></el-input-number>
      </el-form-item>
      <el-form-item prop="isRechargeActivity">
        <el-tooltip class="item" effect="dark" placement="top">
          <i class="el-icon-question" />
          <div style="width: 200px" slot="content">
            勾选则用户在该供应商可以使用赠送金额
          </div>
        </el-tooltip>
        <el-checkbox v-model="dataForm.isRechargeActivity" :disabled="disabled"
          >是否参与优惠活动</el-checkbox
        >
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input
          v-model="dataForm.contact"
          :disabled="disabled"
          placeholder="联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人电话" prop="contactMobile">
        <el-input
          v-model="dataForm.contactMobile"
          :disabled="disabled"
          placeholder="联系人电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="合同有效期" class="typeInput" prop="startDate">
        <el-date-picker
          v-model="date"
          :disabled="disabled"
          class="el-popper DatepickerTime"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </el-form-item>
      <el-button
        size="small"
        @click="$dialog('attachmentAddOrUpdate')"
        v-if="!disabled"
        >上传合同扫描件</el-button
      >
      <el-table
        :data="dataForm.contractAttachmentList"
        border
        style="width: 100%"
      >
        <el-table-column header-align="center" align="center" label="证件名称">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.name"
              placeholder="附件名称"
              :disabled="disabled"
            />
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="openFile(scope.row.url)"
            >
              下载
            </el-button>
            <el-button
              type="text"
              size="small"
              v-if="!disabled"
              @click="deleteHandle(scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
    <attachment-add-or-update
      v-if="attachmentAddOrUpdateVisible"
      ref="attachmentAddOrUpdate"
      @success="getAttachment"
    ></attachment-add-or-update>
  </el-dialog>
</template>

<script>
import { getOrgList } from '@/utils/options';
import AttachmentAddOrUpdate from './attachment-add-or-update';
import { threeListMixin, listMixin, normal } from '@/mixins';
import { keys, pick } from 'lodash';
export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      disabled: false,
      attachmentAddOrUpdateVisible: false,
      visible: false,
      date: [],
      orgList: [],
      dataForm: {
        id: null,
        orgId: '',
        contractName: '',
        contractSn: '',
        contractMoney: '',
        contact: '',
        contactMobile: '',
        startDate: '',
        endDate: '',
        isRechargeActivity: false,
        contractAttachmentList: [],
      },
      dataRule: {
        contractName: [
          { required: true, message: '合同名称不能为空', trigger: 'blur' },
        ],
        orgId: [
          { required: true, message: '配餐公司不能为空', trigger: 'blur' },
        ],
        startDate: [
          { required: true, message: '合同有效期不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  components: {
    AttachmentAddOrUpdate,
  },
  created() {},
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || null;
      this.visible = true;
      this.getOrgList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/tc/contract/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.contract, keys(this.dataForm));
              this.date = [this.dataForm.startDate, this.dataForm.endDate];
            }
          });
        } else {
          this.date = [];
          this.dataForm.contractAttachmentList = [];
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getAttachment(row) {
      this.dataForm.contractAttachmentList.push(...row);
    },

    deleteHandle(id) {
      this.dataForm.contractAttachmentList =
        this.dataForm.contractAttachmentList.filter((i) => i.id !== id);
    },
    openFile(url) {
      window.open(url);
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.startDate = this.date[0];
      this.dataForm.endDate = this.date[1];
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/contract/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
