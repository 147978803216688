<template>
  <el-dialog
    class="warehouse-page fullScreenDialog"
    :title="'限定预售上架商品'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    v-if="visible"
    append-to-body
    width="100%"
  >
    <div>{{ orgName }}</div>
    <div v-for="(item, index) in this.periodTable" :key="index">
      <div>{{ item }}</div>
    </div>
    <div>
      选择日期
      <el-date-picker
        v-model="pickupDate"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        type="date"
        placeholder="选择日期"
        @change="changeDate()"
      >
      </el-date-picker>
    </div>
    <div class="main-container">
      <div class="aside-wrap">
        <h5 class="nameTilCount">
          所有商品
          <div>{{ this.dataList.length }}</div>
          <el-tooltip class="item" effect="dark" placement="top">
            <img src="@/icons/png/freezer.png" />
            <div style="width: 200px" slot="content">进柜商品</div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" placement="top">
            <img src="@/icons/png/canteen.png" />
            <div style="width: 200px" slot="content">堂食商品</div>
          </el-tooltip>
        </h5>
        <el-tree
          class="filter-tree"
          height="calc(100vh - 210px)"
          :data="dataList"
          show-checkbox
          ref="tree2"
          node-key="value"
          @node-click="handleNodeClick"
          @check="handleCheckChangeTree"
          accordion
          :render-after-expand="false"
          label-width="120px"
          :props="defaultProps"
        >
          <template v-slot="{ node, data }">
            <span class="insideTree" v-if="node.level !== 1">
              <img v-if="data.isInFreezer" src="@/icons/png/freezer.png" />
              <img v-if="data.isCanteen" src="@/icons/png/canteen.png" />
              <span>{{ node.label }}</span>
            </span>
            <span class="insideTree" v-else>
              <span>{{ node.label }}</span>
            </span>
          </template>
        </el-tree>
      </div>

      <div class="aside-wrap">
        <h5 class="nameTilCount">
          套餐
          <div>{{ goodsLength }}/{{ packageLength }}</div>
          <el-button type="text" @click="allGoods()" class="rightButton"
            >全选</el-button
          >
        </h5>
        <el-input placeholder="输入关键字" v-model="filterText"> </el-input>
        <div>{{ packageName }}</div>
        <el-tree
          class="filter-tree2"
          height="calc(100vh - 210px)"
          :data="detailList"
          show-checkbox
          ref="tree"
          label-width="120px"
          node-key="value"
          @check="handleCheck"
          :filter-node-method="filterNode"
        >
          <template v-slot="{ node, data }">
            <span class="insideTree" v-if="node.level !== 1">
              <img v-if="data.isInFreezer" src="@/icons/png/freezer.png" />
              <img v-if="data.isCanteen" src="@/icons/png/canteen.png" />
              <span>{{ node.label }}</span>
            </span>
            <span class="insideTree" v-else>
              <span>{{ node.label }}</span>
            </span>
          </template>
        </el-tree>
      </div>

      <div class="main-wrap">
        <h5 class="nameTilCount">
          上架商品
          <div>{{ this.onShelvesList.length }}</div>
          <el-button type="text" @click="clear()" class="rightButton"
            >清空</el-button
          >
        </h5>
        <el-input placeholder="输入关键字" v-model="filterText2"> </el-input>
        <div class="topName">
          <div class="topList">
            <div class="namePart">商品名</div>
            <div class="leftPrice">原价</div>
            <div class="leftPriceInput">现价</div>
            <div class="leftQuantityInput">数量</div>
            <div class="operation">操作</div>
          </div>
        </div>
        <el-tree
          class="filter-tree"
          height="calc(100vh - 210px)"
          :data="
            onShelvesList.filter(
              (e) =>
                !filterText2 ||
                e.name.toLowerCase().includes(filterText2.toLowerCase()),
            )
          "
          label-width="120px"
          default-expand-all
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node insideLine" slot-scope="{ node, data }">
            <div class="item">
              <img v-if="node.data.isInFreezer" src="@/icons/png/freezer.png" />
              <img v-if="node.data.isCanteen" src="@/icons/png/canteen.png" />
              {{ node.data.name }}
            </div>
            <div class="leftPrice">
              <span v-if="node.data.isCollocation !== 0"
                >￥{{ node.data.price }}</span
              >
            </div>
            <div class="leftPriceInput">
              <el-input-number
                v-model="node.data.newPrice"
                :precision="2"
                :controls="false"
                :min="0"
                v-if="node.data.isCollocation !== 0"
              />
            </div>
            <div class="leftQuantityInput">
              <el-input-number
                v-model="node.data.quantity"
                :controls="false"
                :min="0"
                v-if="node.data.isNeedQuantity === 1"
              />
            </div>
            <el-button
              type="text"
              size="mini"
              @click="() => remove(node, data)"
            >
              删除
            </el-button>
          </span>
        </el-tree>
      </div>
    </div>

    <div class="btn-wrap" slot="footer">
      <el-button @click="close()">取消</el-button>
      <el-button
        v-if="pickupDate >= now"
        type="primary"
        @click="dataFormSubmit()"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script setup>
import { dialogMixin, fileMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, fileMixin, normal],
  data() {
    return {
      filterText: '',
      filterText2: '',
      now: new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10),
      pickupDate: new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10),
      disabled: false,
      visible: false,
      dataList: [],
      detailList: [],
      onShelvesList: [],
      goodsLength: 0,
      packageLength: 0,
      defaultList: [],
      defaultProps: {
        disabled: function (data, node) {
          return data.children && data.children.length > 0;
        },
      },
      periodTable: '',
      orgName: '',
      packageName: '',
      dataForm: {
        orgId: '',
        orgPeriodDetailIds: '',
        categoryId: '',
        goodsOrPackageId: '',
        packageDetailIdAndGoodsIds: [],
        packageDetailIds: [],
        goodsAndPackageIds: [],
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {},
  methods: {
    init(orgId, orgPeriodDetailId, orgName, periodTable) {
      this.periodTable = periodTable;
      this.orgName = orgName;
      this.dataForm.orgId = orgId;
      this.dataForm.orgPeriodDetailIds = orgPeriodDetailId;
      this.visible = true;
      this.detailList = [];
      this.dataList = [];
      this.onShelvesList = [];
      this.dataForm.goodsName = null;
      this.dataForm.packageDetailIdAndGoodsIds = [];
      this.packageName = '';
      this.$nextTick(() => {
        this.$http({
          url: '/tc/orgPeriodAdvanceGoods/on-shelves',
          method: 'get',
          params: {
            orgId: this.dataForm.orgId,
            orgPeriodDetailIds: this.dataForm.orgPeriodDetailIds,
            pickupDate: this.pickupDate,
          },
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.dataList = data.data.options[0].leftOptions;
            this.onShelvesList = data.data.options[0].children
              .filter((item) => item.name)
              .map((item) => {
                return item;
              });
            if (data.data.packageDetailIds) {
              this.dataForm.packageDetailIds = data.data.packageDetailIds;
              this.$refs.tree.setCheckedKeys(this.dataForm.packageDetailIds);
            }
            if (data.data.goodsAndPackageIds) {
              this.dataForm.goodsAndPackageIds = data.data.goodsAndPackageIds;
              this.$refs.tree2.setCheckedKeys(this.dataForm.goodsAndPackageIds);
            }
          } else {
            this.dataList = [];
            this.onShelvesList = [];
          }
        });
      });
    },
    changeDate() {
      this.init(this.dataForm.orgId, this.dataForm.orgPeriodDetailIds);
    },
    handleNodeClick(data) {
      this.dataForm.goodsOrPackageId = data.goodsOrPackageId;
      if (data.type === 1 && this.dataForm.goodsOrPackageId) {
        this.$http({
          url: '/tc/orgPeriodAdvanceGoods/on-shelves',
          method: 'get',
          params: {
            packageId: this.dataForm.goodsOrPackageId,
            pickupDate: this.pickupDate,
          },
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.detailList = data.data.option;
            this.packageName = data.data.label;
            this.$nextTick(() => {
              this.goodsLength = this.$refs.tree.getCheckedKeys(true).length;
              this.packageLength = 0;
              this.defaultList = [];
              this.detailList.forEach((item) => {
                item.children.forEach((item) => {
                  this.defaultList.push(item.value);
                });
                this.packageLength += item.num;
              });
            });
          } else {
            this.detailList = [];
          }
        });
      } else {
        this.packageName = '';
        this.detailList = [];
      }
    },
    handleCheckChangeTree(nodeObj, nodeState) {
      this.handleNodeClick(nodeObj);
      const isCheck =
        this.$refs.tree2.getCheckedKeys(true).indexOf(nodeObj.value) > -1;
      if (isCheck) {
        this.onShelvesList.push({
          value: nodeObj.value,
          isCollocation: nodeObj.isCollocation,
          isNeedQuantity: nodeObj.isNeedQuantity,
          name: nodeObj.name,
          isInFreezer: nodeObj.isInFreezer,
          isCanteen: nodeObj.isCanteen,
          price: nodeObj.price,
          newPrice: nodeObj.price,
        });
        this.dataForm.goodsAndPackageIds.push(nodeObj.value);
      } else {
        let deleteIndex = this.onShelvesList.findIndex(
          (i) => i.value === nodeObj.value,
        );
        let deleteIndex1 = this.dataForm.goodsAndPackageIds.findIndex(
          (i) => i === nodeObj.value,
        );
        if (deleteIndex !== -1) {
          this.onShelvesList.splice(deleteIndex, 1);
        }
        if (deleteIndex1 !== -1) {
          this.dataForm.goodsAndPackageIds.splice(deleteIndex1, 1);
        }
      }
    },
    handleCheck(data) {
      if (!data.value) {
        data.children.forEach((item) => {
          let deleteIndex = this.onShelvesList.findIndex(
            (i) => i.value === item.value,
          );
          let deleteIndex1 = this.dataForm.packageDetailIds.findIndex(
            (i) => i === item.value,
          );
          if (deleteIndex !== -1) {
            this.onShelvesList.splice(deleteIndex, 1);
          }
          if (deleteIndex1 !== -1) {
            this.dataForm.packageDetailIds.splice(deleteIndex1, 1);
          }
        });
        data.children.forEach((item) => {
          const isCheck =
            this.$refs.tree.getCheckedKeys(true).indexOf(item.value) > -1;
          if (isCheck) {
            this.onShelvesList.push({
              value: item.value,
              isCollocation: item.isCollocation,
              name: item.name,
              isInFreezer: item.isInFreezer,
              isCanteen: item.isCanteen,
              price: item.price,
              newPrice: item.price,
            });
            this.dataForm.packageDetailIds.push(item.value);
          }
        });
      } else {
        const isCheck =
          this.$refs.tree.getCheckedKeys().indexOf(data.value) > -1;
        if (isCheck) {
          this.onShelvesList.push({
            value: data.value,
            isCollocation: data.isCollocation,
            name: data.name,
            isInFreezer: data.isInFreezer,
            isCanteen: data.isCanteen,
            price: data.price,
            newPrice: data.price,
          });
          this.dataForm.packageDetailIds.push(data.value);
        } else {
          let deleteIndex = this.onShelvesList.findIndex(
            (item) => item.value === data.value,
          );
          let deleteIndex1 = this.dataForm.packageDetailIds.findIndex(
            (item) => item === data.value,
          );
          if (deleteIndex !== -1) {
            this.onShelvesList.splice(deleteIndex, 1);
          }
          if (deleteIndex1 !== -1) {
            this.dataForm.packageDetailIds.splice(deleteIndex1, 1);
          }
        }
      }
      this.$nextTick(() => {
        this.goodsLength = this.$refs.tree.getCheckedKeys(true).length;
      });
    },
    remove(node, data) {
      if (data.type === 2) {
        const index = this.dataForm.packageDetailIds.findIndex(
          (item) => item === data.value,
        );
        this.dataForm.packageDetailIds.splice(index, 1);
        this.$refs.tree.setCheckedKeys(this.dataForm.packageDetailIds);
      } else {
        const index = this.dataForm.goodsAndPackageIds.findIndex(
          (item) => item === data.value,
        );
        this.dataForm.goodsAndPackageIds.splice(index, 1);
        this.$refs.tree2.setCheckedKeys(this.dataForm.goodsAndPackageIds);
      }
      const index1 = this.onShelvesList.findIndex(
        (item) => item.value === data.value,
      );
      this.onShelvesList.splice(index1, 1);
    },
    clear() {
      this.onShelvesList = [];
      this.dataForm.packageDetailIds = [];
      this.dataForm.goodsAndPackageIds = [];
      this.dataForm.packageDetailIdAndGoodsIds = [];
      this.$refs.tree.setCheckedKeys(this.dataForm.packageDetailIds);
      this.$refs.tree2.setCheckedKeys(this.dataForm.goodsAndPackageIds);
    },
    allGoods() {
      if (
        this.defaultList.toString() ===
        this.$refs.tree.getCheckedKeys(true).toString()
      ) {
        this.$refs.tree.setCheckedKeys([]);
      } else {
        this.$refs.tree.setCheckedKeys(this.defaultList);
      }
      this.detailList.forEach((item) => this.handleCheck(item));
    },
    close() {
      this.visible = false;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 表单提交
    dataFormSubmit() {
      this.$http({
        url: `/tc/orgPeriodAdvanceGoods/update-shelves`,
        method: 'post',
        data: {
          orgPeriodDetailIds:
            this.dataForm.orgPeriodDetailIds.length > 1
              ? this.dataForm.orgPeriodDetailIds
                  .split(',')
                  .map((item) => parseInt(item))
              : [this.dataForm.orgPeriodDetailIds],
          packageDetailIdAndGoodsIds: [
            ...this.dataForm.goodsAndPackageIds,
            ...this.dataForm.packageDetailIds,
          ],
          goodsAndPackage: this.onShelvesList,
          pickupDate: this.pickupDate,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
          });
          this.visible = false;
          this.$emit('success');
        } else if (data && data.status === 422) {
          this.$message({
            type: 'error',
            duration: 1500,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.filter-tree {
  height: calc(100vh - 220px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  .el-tree-node__content {
    height: 32px;
  }
  .custom-tree-node {
    //flex: 1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    //align-items: center;
    font-size: 14px;
    padding: 0 8px 0 0;
    line-height: 32px;
    position: relative;
    overflow: hidden;
    .item {
      flex: 1;
    }
    .leftPrice {
      width: 80px;
      font-size: 12px;
      text-align: center;
      color: #000;
    }
    .leftPriceInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .leftQuantityInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .el-button {
      margin-left: 10px;
    }
  }
  .insideTree {
    //flex: 1;
    display: flex;
    flex-wrap: wrap;
    width: auto !important;
    //align-items: center;
    font-size: 14px;
    padding: 0 8px 0 0;
    line-height: 32px;
    position: relative;
    overflow: hidden;
    .item {
      flex: 1;
    }
    .leftPrice {
      width: 80px;
      font-size: 12px;
      text-align: center;
      color: #000;
    }
    .leftPriceInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .el-button {
      margin-left: 10px;
    }
  }
}

.topName {
  //flex: 1;
  height: 32px;
  border-bottom: 1px #efefef solid;
  line-height: 32px;
  position: relative;
  overflow: hidden;
  .topList {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    text-align: center;
    padding: 0;
    .namePart {
      flex: 1;
      text-align: left;
      padding: 0 0 0 24px;
      color: #000;
    }
    .leftPrice {
      width: 80px;
      font-size: 12px;
      text-align: center;
      color: #000;
    }
    .leftPriceInput {
      width: 100px;
      color: #000;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .leftQuantityInput {
      width: 100px;
      color: #000;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .operation {
      width: 50px;
      color: #000;
    }
  }
}
.filter-tree2 {
  height: calc(100vh - 250px);
  overflow-x: hidden;
  overflow-y: auto;
}
.rightButton {
  padding: 0 10px;
  float: right;
}
.showName {
  font-size: 13px;
  color: #000;
  background-color: #cdcdcd;
  padding: 4px 8px;
}

.nameTilCount {
  position: relative;
  float: left;
  font-size: 14px;
  div {
    display: inline-block;
    color: #f00;
  }
}
</style>
