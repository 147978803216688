<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      ref="dataForm"
      :rules="dataRule"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="公司" prop="companyId">
        <el-select v-model="dataForm.companyId" :disabled="disabled">
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="星期" prop="weeks" class="fullList">
        <el-checkbox-group v-model="dataForm.weeks" size="medium">
          <el-checkbox-button
            v-for="(item, index) in weeksList"
            :key="index"
            :label="item.id"
            >{{ item.week }}</el-checkbox-button
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCompanyList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        companyId: '',
        weeks: [1, 2, 3, 4, 5, 6, 7],
      },
      companyList: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataRule: {
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        weeks: [{ required: true, message: '星期不能为空', trigger: 'blur' }],
      },
    };
  },

  created() {},

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.getCompanyList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companyworkweeks/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.companyworkweeks, keys(this.dataForm));
              this.dataForm.weeks = data.companyworkweeks.weeks
                ? data.companyworkweeks.weeks
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      let sortedWeeks = this.dataForm.weeks.sort((a, b) => a - b);
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/companyworkweeks/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              companyId: this.dataForm.companyId,
              weeks: sortedWeeks.map((i) => i).join(','),
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
  },
};
</script>
