<template>
  <el-dialog
    title="仓位分配"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select
            v-model="dataForm.orgId"
            :disabled="disabled"
            @change="getPeriodList()"
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            :disabled="disabled"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="星期" prop="weeks" class="fullList">
          <el-checkbox-group
            :disabled="disabled"
            v-model="dataForm.weeks"
            class="checkBtnColor"
            size="medium"
            @change="getSpaceList()"
          >
            <el-checkbox-button
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="时段" prop="orgPeriodId">
          <el-select
            v-model="dataForm.orgPeriodId"
            :disabled="disabled"
            filterable
            clearable
            multiple
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.periodWithTimeName"
              :value="item.orgPeriodId"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="newTablePart">
        <div class="firstTable">
          <el-table :data="usedSpace" border stripe height="300px">
            <el-table-column
              prop="freezerName"
              header-align="center"
              align="center"
              label="智能取餐柜名称"
            />
            <el-table-column
              prop="space"
              header-align="center"
              align="center"
              label="分配仓位"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.space"
                  :controls="false"
                  :min="0"
                  :precision="0"
                  class="inputNumberInside"
                  @change="changeCount($event, scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="newTable">
          <div class="tablePart" v-if="disabled">
            <el-button
              type="primary"
              @click="addSpace()"
              class="el-icon-add-location"
              v-if="disabled"
              >添加</el-button
            >
          </div>
          <el-table
            :data="spaceList"
            border
            stripe
            ref="spaceList"
            @selection-change="selectionChange"
            height="300px"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="freezerName"
              header-align="center"
              align="center"
              label="智能取餐柜名称"
            />
            <el-table-column
              prop="space"
              header-align="center"
              align="center"
              label="总仓位"
            />
            <el-table-column
              prop="usedSpace"
              header-align="center"
              align="center"
              label="已使用仓位"
            />
            <el-table-column
              prop="remainingSpace"
              header-align="center"
              align="center"
              label="剩余仓位"
            />
            <el-table-column
              header-align="center"
              align="center"
              label="分配仓位"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.cage"
                  :controls="false"
                  :min="0"
                  :precision="0"
                  class="inputNumberInside"
                  @change="countNum($event, scope.$index)"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCompanyList, getOrgList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: true,
      visible: false,
      time: [],
      startTime: '',
      endTime: '',
      dataForm: {
        id: '',
        orgId: '',
        periodId: '',
        companyId: '',
        orgPeriodId: [],
        startDate: '',
        endDate: '',
        weeks: [1, 2, 3, 4, 5, 6, 7],
        periodWithTimeName: '',
      },
      orgList: [],
      companyList: [],
      periodList: [],
      usedSpace: [],
      initialSpace: [],
      Space: [],
      spaceList: [],
      orgPeriodCompany: [],
      dataListSelections: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataRule: {},
    };
  },
  created() {},
  methods: {
    init(id) {
      this.dataForm.orgPeriodId = [];
      this.periodList = [];
      this.dataForm.id = id || '';
      this.visible = true;
      this.getOrgList();
      this.getCompanyList();
      this.getPeriodList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.usedSpace = [];
        this.initialSpace = [];
        this.Space = [];
        this.spaceList = [];
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgPeriodCompany/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.orgPeriodCompany, keys(this.dataForm));
              this.dataForm.weeks = data.orgPeriodCompany.weeks
                ? data.orgPeriodCompany.weeks
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
              this.dataForm.orgPeriodId = [data.orgPeriodCompany.orgPeriodId];
              this.getPeriodList();
              this.usedSpace = data.orgPeriodCompany.usedSpace;
              this.initialSpace = this.usedSpace.map((item) => {
                return {
                  freezerId: item.freezerId,
                  orgPeriodFreezerId: item.orgPeriodFreezerId,
                  space: item.space,
                };
              });
              this.spaceList = data.orgPeriodCompany.spaceList;
            }
          });
        }
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    //
    selectionChange(val) {
      this.dataListSelections = val;
    },
    countNum(data, theIndex) {
      this.spaceList.forEach((i, index) => {
        if (index === theIndex) {
          if (parseInt(i.remainingSpace - data) < 0) {
            i.cage = i.remainingSpace;
            this.$message.error(
              `最大可选择的仓位数量为${i.remainingSpace},已为您选择最大可选择仓位数`,
            );
          }
        }
      });
    },
    addSpace() {
      this.Space = this.dataListSelections
        .filter((item) => item.space > 0)
        .map((item) => {
          return {
            freezerId: item.freezerId,
            orgPeriodFreezerId: item.orgPeriodFreezerId,
            freezerName: item.freezerName,
            space: item.cage,
          };
        });
      for (let i = 0; i < this.Space.length; i++) {
        if (
          this.spaceList.filter(
            (item) =>
              item.orgPeriodFreezerId === this.Space[i].orgPeriodFreezerId,
          ).length > 0
        ) {
          let space = this.spaceList.filter(
            (item) =>
              item.orgPeriodFreezerId === this.Space[i].orgPeriodFreezerId,
          )[0];
          space.remainingSpace -= this.Space[i].space;
          space.usedSpace += this.Space[i].space;
          space.cage = 0;
        }
        if (
          this.usedSpace.filter(
            (item) =>
              item.orgPeriodFreezerId === this.Space[i].orgPeriodFreezerId,
          ).length > 0
        ) {
          this.usedSpace.filter(
            (item) =>
              item.orgPeriodFreezerId === this.Space[i].orgPeriodFreezerId,
          )[0].space += this.Space[i].space;
        } else {
          this.usedSpace.push({
            freezerId: this.Space[i].freezerId,
            orgPeriodFreezerId: this.Space[i].orgPeriodFreezerId,
            freezerName: this.Space[i].freezerName,
            space: this.Space[i].space,
          });
        }
      }
      this.initialSpace = this.usedSpace.map((item) => {
        return {
          freezerId: item.freezerId,
          orgPeriodFreezerId: item.orgPeriodFreezerId,
          space: item.space,
        };
      });

      this.$nextTick(() => {
        this.dataListSelections = [];
        this.$refs.spaceList.clearSelection();
      });
    },
    changeCount(data, row) {
      let space = this.initialSpace.filter(
        (item) => item.orgPeriodFreezerId === row.orgPeriodFreezerId,
      )[0];
      let diffSpace = space.space - row.space;
      let spaceList = this.spaceList.filter(
        (item) => item.orgPeriodFreezerId === row.orgPeriodFreezerId,
      )[0];
      spaceList.remainingSpace += diffSpace;
      spaceList.usedSpace -= diffSpace;
      space.space = row.space;
      if (row.space === 0) {
        this.usedSpace.splice(
          this.usedSpace.findIndex(
            (item) => item.orgPeriodFreezerId === row.orgPeriodFreezerId,
          ),
          1,
        );
      }
    },
    getPeriodList() {
      if (this.dataForm.orgId) {
        this.$http({
          url: `/tc/orgperiod/combo/${this.dataForm.orgId}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
            this.time = this.periodList.filter(
              (item) => item.id === this.dataForm.orgPeriodId[0],
            );
            this.startTime = this.periodList.startTime;
            this.endTime = this.periodList.endTime;
          }
        });
      }
    },
    getSpaceList() {
      this.usedSpace = [];
      if (this.dataForm.orgPeriodId.length > 1) {
        this.dataForm.isUnified = true;
        this.dataForm.isReservationUnified = true;
      }
      if (
        this.dataForm.weeks.length > 0 &&
        this.dataForm.orgPeriodId.length > 0 &&
        this.dataForm.startDate &&
        this.dataForm.endDate
      ) {
        let sortedWeeks = this.dataForm.weeks.sort((a, b) => a - b);
        const weeks = sortedWeeks.join(',');
        const orgPeriodId = this.dataForm.orgPeriodId.join(',');
        this.$http({
          url: `/tc/orgPeriodCompanyFreezer/spaceList`,
          method: 'get',
          params: {
            weeks: weeks,
            orgPeriodIds: orgPeriodId,
            startDate: this.dataForm.startDate,
            endDate: this.dataForm.endDate,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            if (data.spaceList && data.spaceList?.length) {
              data.spaceList.forEach((i) => {
                i.cage = 0;
              });
            }
            this.spaceList = data.spaceList;
          }
        });
      } else {
        this.spaceList = [];
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgPeriodCompanyFreezer/companyFreezer`,
            method: 'post',
            data: {
              orgPeriodCompanyId: this.dataForm.id,
              startDate: this.dataForm.startDate,
              endDate: this.dataForm.endDate,
              orgId: this.dataForm.orgId,
              periodId: this.dataForm.periodId,
              companyId: this.dataForm.companyId,
              spaceList: this.usedSpace,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.code === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inputNumberInside {
  width: 100%;
  .el-input__inner {
    border: none;
  }
}
.tableLine {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  .leftLine {
    font-size: 12px;
    width: 160px;
    padding: 0 10px 0 0;
    text-align: left;
  }
  .rightLine {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .longTime {
      width: 100%;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
    .leftname {
      width: 90px;
      text-align: center;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
    .halfTime {
      width: auto;
      flex: 1;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}

.newTablePart {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  .newTable {
    width: 70%;
    position: relative;
  }
  .firstTable {
    width: 30%;
    position: relative;
  }
}
</style>
